<template>
  <v-sheet class="d-flex justify-center">
    <v-sheet max-width="782" class="d-flex justify-center">
      <v-sheet class="pt-8 px-6" width="782">
        <div class="nunito">
          <h1 :style="`color: ${wsDARKER}`" >Ліцензійний публічний договір (Публічна
            Оферта)</h1>
          <h5 :style="`color: ${wsACCENT}`" class="font-weight-light ">Редакція від 21 травня 2022 року</h5>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-5">I. Загальні Положення</h3>
          <ol style="list-style-type: decimal;">
            <li>Товариство з обмеженою відповідальністю <b class="font-weight-medium">«ВІСТАДІ»</b> створило
              онлайн-платформу, яка поєднує декілька функціональних площадок, що дозволяє особам, які
              зареєструвалися на платформі здійснювати певний функціонал, наданий онлайн-платформою та відповідними
              її частинами - функціональними площадками (надалі разом <b class="font-weight-medium">«Платформа»</b>
              та кожна окрема <b class="font-weight-medium">«Функціональна Площадка»</b>). Доступ до Платформи
              здійснюється через веб-сайт <a href="https://westudy.ua/online" target="_blank">[
                https://westudy.ua/online ]</a>, доступ до Функціональних Площадок може здійснюватися через веб-сайт
              Платформи. Платформа постійно розвивається, тому кількість її Функціональних Площадок може
              змінюватися.
            </li>
            <li>Цей Ліцензійний публічний договір на дозвіл використання Платформи(надалі <b
                class="font-weight-medium">«Договір»</b>) є договором між товариством з обмеженою відповідальністю
              <b class="font-weight-medium">«ВІСТАДІ»</b> в особі директора Рижкова Ростислава Сергійовича, яка діє
              на підставі Статуту (надалі - <b class="font-weight-medium">«ТОВ “ВІСТАДІ”»</b>, <b
                  class="font-weight-medium">«Ліцензіар»</b>) та Вами(надалі <b class="font-weight-medium">«Ви»</b>,
              <b class="font-weight-medium">«Клієнт»</b>, <b class="font-weight-medium">«Ліцензіат»</b>, як
              зазначено нижче), що надалі разом іменуються <b class="font-weight-medium">Сторони</b>, а кожний
              окремо - <b class="font-weight-medium">Сторона</b>.
            </li>
            <li>Цей Договір встановлює порядок та визначає умови отримання дозволу на використання Платформи
              Клієнтом, а Клієнт за умови безумовного та повного погодження з усіма умовами пропозиції ТОВ
              “ВІСТАДІ”, викладеної в цьому Договорі та Додатках до нього, акцептує цей Договір у спосіб, визначений
              цим Договором, що свідчить про повну згоду приєднання Клієнта з умовами до цього Договору.
            </li>
            <li>Додаткові умови можуть застосовуватися до надання доступу та використання певних видів та типів
              Функціональних Площадок та процесу використання Платформи, такі як політики, умови та правила
              використання та ін.. Такі додаткові умови встановлюються додатковими угодами, політиками, правилами,
              договорами тощо, доступ до яких здійснюється через веб-сайт Платформи. Такі додаткові угоди, політики,
              правила, договори вважаються невід’ємною частиною цього Договору. Додаткові умови повинні мати
              перевагу над цим Договором у разі виникнення конфлікту щодо відповідних Функціональних Площадок.
            </li>
            <li>Якщо Клієнт в будь-який момент не погоджується з будь-якою умовою цього Договору та/або невід’ємних
              частин цього Договору, він не може акцептувати цей Договір і продовжити використання Платформи.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">II. Визначення та Терміни</h3>
          <ol style="list-style-type: decimal;">
            <li><b class="font-weight-medium">Договір</b> - має значення, вказане у Пункті 2 Розділу I «Загальні
              Положення».
            </li>
            <li><b class="font-weight-medium">Ви(а також Клієнт)</b> - сторона Договору, фізична особа, що досягла
              18 повних років - особисто, ФОП, юридична особа - в особі уповноваженого представника державного
              закладу освіти, що акцептувала цей Договір.
            </li>
            <li><b class="font-weight-medium">Платформа</b> - має значення, вказане у Пункті 1 Розділу I; сукупність
              пов’язаних між собою електронної (цифрової) інформації, спеціалізованого програмного забезпечення,
              прикладних програм, виражених у вихідному та/або об’єктному кодах серверної частини (бек-енд),
              клієнтської частини (фронт-енд) та інших програмних частин, дизайну, графічних елементів, текстів,
              описів, схем, баз даних, комп’ютерних програм, що разом утворюють онлайн-платформу під назвою
              <b class="font-weight-medium">«WeStudyTM Online»</b>, доступ та використання якої може здійснюватися
              лише на умовах, визначених ТОВ
              “ВІСТАДІ”-власником авторських та майнових прав, за допомогою веб-сайту Платформи
              [<a href="https://westudy.ua/online" target="_blank">https://westudy.ua/online</a>].
            </li>
            <li><b class="font-weight-medium">Функціональна Площадка</b> - віртуальна площадка на Платформі, до якої
              можуть приєднатися Користувачі, що взаємодіють між собою.
            </li>
            <li><b class="font-weight-medium">Користувач</b> - фізична особа, що створила Профіль на Платформі.</li>
            <li><b class="font-weight-medium">Профіль</b> - обліковий запис, що створюється внаслідок проходження
              процесу реєстрації та авторизації на Платформі. Профіль Користувача вважається пов’язаним з особою, що
              прийняла цей Договір та Додатки до нього.
            </li>
            <li><b class="font-weight-medium">Ліцензія</b> - відклична, невиключна ліцензія (дозвіл) на використання
              Платформи, що надається ТОВ “ВІСТАДІ” — Ліцензіаром Клієнту-Ліцензіату на умовах цього Договору та
              Додатків до нього.
            </li>
            <li><b class="font-weight-medium">Функціонал Ліцензії</b> - обсяг функцій, дозволених дій на Платформі
              та функціональних можливостей, у тому числі і Функціональних Площадок.
            </li>
            <li><b class="font-weight-medium">Ліцензійний код</b> - унікальна сукупність символів та/або літер
              та/або цифр та/або гіперпосилань, що генерується ТОВ “ВІСТАДІ” і використовується Клієнтом через
              Профіль Користувача Платформи для активації/доступу до Функціоналу Ліцензії.
            </li>
            <li><b class="font-weight-medium">Дієздатність</b> - це здатність особи своїми діями набувати для себе
              цивільних прав та самостійно їх здійснювати, а також здатність своїми діями створювати для себе
              цивільні обов’язки, самостійно їх виконувати та нести відповідальність у разі їх невиконання.
            </li>
            <li><b class="font-weight-medium">Додаток до Договору</b> - невід’ємна частина Договору, що регулює
              окремі аспекти, містить важливу інформацію, яка доповнює Договір.
            </li>
            <li><b class="font-weight-medium">Акцепт</b> - повне, добровільне та безумовне прийняття Клієнтом умов
              Договору та Додатків до нього, що здійснюється внаслідок завершення активації Профіля Користувача, що
              відбувається під час реєстрації та передбачає проставлення аналогу власноручного підпису на
              веб-сторінці <a href="https://westudy.ua/online" target="_blank">[https://westudy.ua/online]</a>,
              відповідно до умов цього Договору.
            </li>
            <li><b class="font-weight-medium">Факсиміле</b> - факсимільне відтворення підпису уповноваженої особи
              ТОВ “ВІСТАДІ” та відтиску печатки ТОВ “ВІСТАДІ” за допомогою засобів механічного, електронного або
              іншого копіювання як аналога власноручних підпису та відтиску печатки.
            </li>
            <li><b class="font-weight-medium">Підпис Клієнта</b> - аналог власноручного підпису Клієнта, який
              використовується для позначення акцепту Договору відповідно до умов цього Договору та складається з
              чотирьох наборів аутентифікаційних даних: Прізвища та Ім’я Клієнта введеного при реєстрації, вказаного
              email, який вводиться при реєстрації на Платформі та одноразового посилання з зашифрованим кодом, який
              було направлено на вказаний email після вводу такого email.
            </li>

          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">III. Платформа</h3>

          <ol style="list-style-type: decimal;">
            <li>Платформа, надання дозволу та умови використання якої регулюються цим Договором, є онлайн
              платформою, що дозволяє реалізовувати та впроваджувати окремі проєкти, здійснювати діяльність та/або
              її окремі аспекти, пов’язані з процесом навчання, отриманням освіти, виховним процесом, процесом
              отримання нових знань, умінь, навичок тощо. Платформа жодним чином не замінює навчальний, виховний
              та/або освітній процес, вона покликана доповнювати його в рамках, що не суперечать чинному
              законодавству України.
            </li>
            <li>Платформа постійно розвивається та змінюється, ТОВ “ВІСТАДІ” залишає за собою право у будь-який
              час додати та/або змінити умови та обсяг функціоналу, змінити кількість Функціональних Площадок, умови
              їх використання та доступний на них функціонал. Клієнт погоджується з тим, що такі зміни можуть
              призвести до зміни істотних умов цього Договору, що будуть відображені ТОВ “ВІСТАДІ” у новій редакції
              Договору за 10 днів до вступу цих змін в силу.
            </li>
            <li>Використовуючи Платформу та приймаючи цей Договір, Клієнт визнає, що ТОВ “ВІСТАДІ” не є учасником
              відношень між Користувачами та/або Клієнтами, не контролює поведінку Користувачів та Клієнтів
              Платформи та не має будь-якого відношення до жодного навчального, виховного, освітнього процесу на
              Платформі та його складових.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">IV. Акцепт умов Договору</h3>
          <ol style="list-style-type: decimal;">
            <li>Для отримання можливості використання Платформи Клієнт має акцептувати (прийняти) Договір.
              Акцептування Договору та Додатків відбувається під час реєстрації на веб-сайті Платформи. Акцептування
              Договору та Додатків до нього відбувається за допомогою введення аналогу власноручного підпису —
              Заповнення даних Клієнтом у спеціальне поле та натискання кнопки <b class="font-weight-medium">«Зареєструватися»</b>
              й здійснення наступних дій. Договір вважається акцептованим (прийнятим) після того, як Клієнт завершив
              активацію
              свого Профілю, що відбувається внаслідок реєстрації, через надісланий на email, одноразового посилання
              з зашифрованим кодом та натискання кнопки <b class="font-weight-medium">«Підтвердити пошту»</b> або
              <b class="font-weight-medium">натискання переходу за відповідним посиланням для підтвердження
                пошти.</b>
            </li>
            <li>Керуючись положеннями частини 3 статті 207 Цивільного Кодексу України, Сторони погодили та
              домовилися, що такий формат акцепту Договору без підписання письмового примірника має юридичну силу та
              визнається обома Сторонами. Прийнятий у такий спосіб Договір та Додатки до нього, підтвердженні інші
              документи між Сторонами визнаються такими, що вчинені із додержанням письмової форми та підписані
              власноруч.
            </li>
            <li>Підпис Клієнта є аналогом власноручного підпису особи, що має право та відповідні повноваження
              укладати цей Договір.
            </li>
            <li>Підпис Клієнта складається з 4 (чотирьох) наборів аутентифікаційних даних, які однозначно
              ідентифікують Клієнта, як особу, що прийняла умови Договору, а саме Прізвища та Ім’я Клієнта введеного
              при реєстрації, вказаного email, який вводиться при реєстрації на Платформі та одноразового посилання
              з зашифрованим кодом, який було направлено на вказаний email після вводу такого email та натискання
              кнопки «Підтвердити пошту» або натискання переходу за посиланням в надісланому після реєстрації листі
              підтвердження на вказаний email.
            </li>
            <li>Для створення Профілю, Клієнт має на веб-сайті Платформи
              [<a href="https://westudy.ua/online" target="_blank">https://westudy.ua/online</a>] заповнити
              потрібну для реєстрації інформації, яка має відповідати дійсності.
            </li>
            <li>У випадку будь-яких змін в реєстраційній інформації, Клієнт зобов’язаний відповідно оновити свій
              Профіль не пізніше 10 календарних днів та повідомити про це ТОВ “ВІСТАДІ”.
            </li>
            <li>ТОВ “ВІСТАДІ” має право провести перевірку вказаних при реєстрації даних. ТОВ “ВІСТАДІ” має право
              витребувати у Клієнта документи та/або копії, скан-копії, документів, що підтверджують наведені при
              реєстрації дані. У разі виникнення питань та/або уточнень ТОВ “ВІСТАДІ” може зв’язатися з Клієнтом за
              вказаними при реєстрації контактними даними.
            </li>
            <li>ТОВ “ВІСТАДІ” має право відмовити особі в реєстрації та подальшому використанні Платформи, якщо:
            </li>
            <ul style="list-style-type: square;">
              <li>особою були наведені дані, що не відповідають дійсності, неправдива інформація, дані іншої особи
                без її згоди на це, інформація була наведена з метою введення в оману ТОВ “ВІСТАДІ”;
              </li>
              <li>особа відмовилася та/або не надала у встановлений ТОВ “ВІСТАДІ” термін документи та/або копії,
                скан-копії, документів, що підтверджують наведені при реєстрації дані;
              </li>
              <li>ТОВ “ВІСТАДІ” не вдалося у розумний термін зв’язатися з особою для отримання уточнень щодо її
                даних за вказаними нею при реєстрації контактними даними;
              </li>
              <li>буде виявлено, що наведені дані, у тому числі email та номер мобільного телефону вже
                неодноразово (понад 10 разів) використовувалися для реєстрації на Платформі.
              </li>
            </ul>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">V. Ліцензія</h3>
          <ol style="list-style-type: decimal;">
            <li>Ця Платформа та усі її компоненти та частини, які включають, серед іншого, вихідний і об’єктний
              код серверної частини (бек-енд), клієнтської частини (фронт-енд) та інших програмних частин веб-сайту,
              дизайн, графічні елементи, тексти, описи, сторінки веб-сайту, схеми, бази даних, іншу документацію та
              інші матеріали, пов’язані зі створенням та підтриманням Платформи, є об’єктами прав інтелектуальної
              власності ТОВ “ВІСТАДІ” та захищені відповідно до законодавства України про інтелектуальну власність
              та нормами міжнародного права у сфері захисту прав інтелектуальної власності.
            </li>
            <li>За цим Договором, після акцепту Договору та Додатків до нього Клієнт — Ліцензіат отримує, а ТОВ
              “ВІСТАДІ” — Ліцензіар, якому належить виключне право на використання Платформи, надає відкличну,
              невиключну ліцензію (дозвіл) на невиключне право використання Платформи відповідно до умов цього
              Договору та Додатків до нього (надалі — <b class="font-weight-medium">«Ліцензія»</b>).
            </li>
            <li>За Ліцензією Ліцензіат отримує невиключне право на використання Платформи за допомогою
              Ліцензійного коду.
            </li>
            <li>Ліцензіат має право в рамках користування Платформою надавати іншим фізичним особам можливість
              використання Платформи у кількості наявних у нього Ліцензійних кодів згідно з умовами цього Договору
              та Додатків. Особа, що отримала код активації має створити Профіль для того, щоб мати можливість
              користуватися Платформою.
            </li>
            <li>Ліцензіат має право в рамках користування Платформою через пов’язаний з ним Профіль Користувача
              бути підключеним іншим Користувачем до Об’єкту Платформи та/або іншої частини Платформи. У випадку
              підключення Ліцензіата іншим Користувачем, підключений Ліцензіат може використовувати обсяг
              Функціоналу, що надається Користувачу щодо відповідного Функціональної Площадки, який підключив
              Ліцензіата. Ліцензіат у жодний спосіб не приєднується до умов Ліцензії особи, що його підключила.
              Підключення не змінює умов та Функціоналу Ліцензії підключеного Ліцензіата.
            </li>
            <li>Ліцензіат не має права передавати та/або відчужувати Ліцензійний код або коди іншим особам.
              Ліцензіат не має права видавати субліцензії.
            </li>
            <li>За Ліцензією Ліцензіат отримує невиключне право на використання Платформи такими способами:</li>
            <ul style="list-style-type: square;">
              <li>відтворення Платформи;</li>
              <li>користування Платформою в рамках Функціоналу Ліцензії, відповідно до умов цього Договору та
                Додатків до нього.
              </li>
            </ul>
            <li>Під «відтворенням» Платформи в рамках цього Договору Сторони розуміють та визначають невиключне
              право створювати в необмеженій кількості екранні копії і кешовані копії, зроблені в процесі перегляду
              веб-сайту Платформи за умови, що такі копії є тимчасовими, тобто не призначені для довгого чи
              постійного зберігання та повторного багаторазового відтворення, мають перехідний або випадковий
              характер та складають невід’ємну і істотну частину технологічного процесу використання Платформи,
              дозволенного цим Договором та Додатками до нього.
            </li>
            <li>Під «користуванням» Платформою в рамках цього Договору Сторони розуміють та визначають усвідомлене
              та вільне волевиявлення Ліцензіата, що проявляється у використанні технічних можливостей Платформи на
              умовах, визначених цим Договором та Додатками до нього та наданою Ліцензією.
            </li>
            <li>Ліцензіар жодним чином не надає Ліцензіату право на копіювання, декомпіляцію доробку, переклад,
              переробка, реінжиніринг, вилучення чи намагання вилучити вихідний та/або об’єктний код, їх компоненти
              та/або контент Платформи та її Функціональних Площадок, будь-яке інше відтворення, що передбачає запис
              та довгострокове зберігання, або таке відтворення, що не відповідає положенням Пункту 3 цього Розділу,
              використання, яке заборонене, або прямо не дозволене цим Договором та Додатками до нього.
            </li>
            <li>Територією на яку поширюється дозвіл використовувати Платформу, тобто територією правомірного
              використання Платформи є територія України, визначена чинним законодавством України. Ліцензіар
              дозволяє використання Платформи за цим Договором в інших державах, за умови, якщо така держава є
              державою реєстрації Ліцензіата та про неї було повідомлено Ліцензіара при реєстрації, або протягом 5
              (п’яти) робочих днів з моменту набрання чинності нової реєстрації.
            </li>
            <li>При використанні Платформи Ліцензіату забороняється використовувати її іншим чином, ніж це
              передбачено цим Договором та Додатками до нього. Ліцензіату забороняється без згоди Ліцензіара
              поміщати компоненти Платформи до будь-якого іншого програмного забезпечення та систем, здійснювати
              будь-яке розміщення Платформи чи її компонентів в мережі інтернет.
            </li>
            <li>Поданий Договір не передбачає будь-якого відчуження або іншої передачі прав інтелектуальної
              власності на Платформу. Ніщо в цьому Договорі не означає що Ліцензіат отримав права володіння або
              розпорядження чи власності, або право продати чи здійснити відчуження в інший спосіб Платформи, як
              об’єктів майнових авторських прав, що належать Ліцензіару.
            </li>
            <li>Невиключне право на використання об’єктів авторського права вважається наданими ТОВ “ВІСТАДІ” та
              прийнятим Клієнтом, якщо протягом 3 (трьох) робочих днів з дня реєстрації Клієнт не надішле ТОВ
              “ВІСТАДІ” вмотивовану письмову відмову від прийняття таких прав.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6"> VI. Користування Платформою</h3>

          <ol style="list-style-type: decimal;">
            <li>Користування Платформою, її Функціональними Площадками та технічними можливостями завжди має
              відбуватися відповідно до умов цього Договору, Додатків до нього та чинного законодавства України.
            </li>
            <li>Користувач не має права надавати в користування свій Профіль будь-якій третій особі. Користувач не
              може купувати, продавати та дарувати свій Профіль, а також пропонувати такі дії.
            </li>
            <li>Фактичні можливості Користувача та доступний йому функціонал Ліцензії на Платформі встановлюються у
              відповідності з цим Договором та Додатками до нього.
            </li>
            <li>Спеціальний код для активації та/або авторизації на Платформі є кодом за допомогою якого один
              Клієнт може підключити іншого Користувача до створеного ним Функціональної Площадки та відповідно
              надати обсяг функціоналу, передбачений цим Договором та його Додатками. Код стає активним після його
              введення в спеціальне поле на веб-сайті Платформі у відповідності до інструкцій, наведених на
              веб-сайті Платформи та/або у листі-запрошенні, яким було надіслано спеціальний Код.
            </li>
            <li>Спеціальний код генерується ТОВ “ВІСТАДІ” та/або через Профіль Користувача.</li>
            <li>Підключаючи Користувача до Функціональної Площадки, Клієнт бере на себе відповідальність та
              зобов’язується сприяти оптимальному використанню Платформи таким Користувачем, постійно, слідкувати за
              відповідністю дій такого Користувача в рамках Функціональної Площадки положенням Договору та Додатків
              до нього. Клієнт зобов’язується повідомляти ТОВ “ВІСТАДІ” про порушення Користувачем, який
              використовує такий спеціальний код, умов цього Договору та Додатків до нього протягом 24 годин з
              моменту вчинення Користувачем такого порушення.
            </li>
            <li>Клієнт може деактивувати Користувача.</li>
            <li>У випадку видалення Функціональної Площадки, спеціальний код для активації та/або авторизації на
              Платформі автоматично стає неактивним.
            </li>
            <li>Користування Платформою Користувачем в залежності від обраної Функціональної Площадки може
              включати здійснення дій Користувачем в якості певної категорії Користувача. Для різних категорій
              Користувачів доступний різний набір функціоналу та можливостей згідно з їхніми ролями на конкретній
              Функціональній Площадці.
            </li>
            <li>ТОВ “ВІСТАДІ” надає Користувачу право в межах технічних можливостей Платформи та відповідно до
              умов Договору, Угоди Користувача та інших Додатків до Договору, надавати, завантажувати, публікувати
              або будь-яким іншим способом робити доступними для Платформи та Користувачів, текстові, фото-, аудіо-,
              відео матеріали та будь-яку іншу інформацію, включаючи пов’язані з Функціональними Площадками та,
              коментарі, відгуки, обмін ними між Користувачами (надалі — <b class="font-weight-medium">«Користувацький
                Контент»</b>).
              Формат та предмет завантаження Користувацького Контенту визначається ТОВ “ВІСТАДІ”.
            </li>
            <li>Будь-який Користувацький Контент розглядається, як такий відносного якого Клієнт, через профіль
              Користувача якого Користувацький Контент потрапив на Платформу, має відповідні права, у тому числі
              авторські права та/або інші права інтелектуальної власності та/або матеріальні права, що дозволяють
              йому згідно з вимогами законодавства України здійснювати такі дії на Платформі.
            </li>
            <li>Клієнт надає ТОВ “ВІСТАДІ” право відтворювати весь чи частину Користувацького Контенту на
              будь-яких цифрових носіях для запису, відомих та ще невідомих і таких, що виникнуть в майбутньому, і,
              зокрема, на будь-яких серверах, жорстких дисках, картах пам’яті, чи будь-яких рівноцінних носіях, у
              будь-якому форматі і з будь-якою обробкою, відомою чи ще невідомою, в обсязі, необхідному для
              будь-якої операції зі зберігання, відтворення, резервного копіювання, передачі або завантаження,
              пов’язаних із функціонуванням Платформи на умовах безвідкличної невиключної ліцензії, що поширюється
              на території всього світу та з терміном дії — 70 (сімдесят) календарних років.
            </li>
            <li>Сторони погоджуються, що Функціональні Площадки з моменту їх створення Користувачем є власністю
              ТОВ “ВІСТАДІ”, це жодним чином не позбавляє Клієнта прав інтелектуальної власності на об’єкти,
              розміщені в рамках створеного Об’єкта Платформи. Це положення жодним чином не мінімізує чи нівелює
              відповідальність та обов’язки Клієнта, що встановлюються цим Договором та Додатками до нього.
            </li>
            <li>14. Цим Договором Клієнт надає свою згоду на те, що ТОВ “ВІСТАДІ” може аналізувати інформацію та
              контент, що надаються, передаються, розміщуються на Платформі, її Функціональних Площадках, або
              надається, передається, розміщується іншими Користувача та має пряме або опосередковане відношення до
              Клієнта та/або Користувача. Результати таких аналізів можуть бути передані ТОВ “ВІСТАДІ” іншим особам
              та/або Користувачам, або надані в публічний доступ без повідомлення про це Користувача та/або Клієнта.
              ТОВ “ВІСТАДІ” може відстежувати відвідуваність та дії Користувача на Платформі, її Функціональних
              Площадках, створювати відповідну аналітику та надавати статистику отриману в ході своїх досліджень, на
              Платформі Користувачу або Користувачам та/або Клієнта, або в публічний доступ без повідомлення про це
              Користувача та/або Клієнта.
            </li>
            <li>15. У випадку видалення Профілю Користувача, Користувацький Контент та створенні ним Функціональні
              Площадки не видаляються автоматично та можуть залишатися на Платформі протягом часу, як це вважатиме
              за потрібне ТОВ “ВІСТАДІ”.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">VII. Поставка програмної продукції</h3>
          <ol style="list-style-type: decimal;">
            <li>1. Надання та продовження терміну дії Ліцензії, що у тому числі включає отримання Клієнтом
              Ліцензійних кодів, відповідно до цього Договору є постачанням програмної продукції в розумінні пункту
              26¹ Підрозділу 2 Розділу ХХ «Перехідні положення» Податкового кодексу України. Операції з поставки
              програмної продукції тимчасово (до 1 січня 2023 року) звільняються від оподаткування ПДВ.
            </li>
            <li>Поставка програмної продукції здійснюється шляхом надання Клієнту доступу до веб-сайту Платформи.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">VIII. Термін дії Ліцензії</h3>
          <ol style="list-style-type: decimal;">
            <li>Термін дії Ліцензії — період часу протягом якого Ліцензіат має право на використання Платформи
              відповідно до умов цього Договору, відповідає терміну дії підписки за тарифом на Платформі, яку було
              обрано та сплачено Ліцензіатом. Договір набуває чинності з моменту його акцепту, тобто календарної
              дати здійснення реєстрації та авторизації Профілю Користувача.
            </li>
            <li>Сторони прийшли згоди, що термін дії Ліцензії вважається продовженим, якщо Ліцензіатом сплачено
              наступний період підписки за тарифом на Платформі.
            </li>
            <li>Термін дії Ліцензії може бути припинений у будь-який час Клієнтом шляхом видалення пов’язаного з
              ним Профілю Користувача.
            </li>
            <li>Термін дії Ліцензії може бути припинений у будь-який момент ТОВ “ВІСТАДІ” шляхом видалення
              пов’язаного з Клієнтом Профілю Користувача. ТОВ “ВІСТАДІ” має право видалити пов’язаний з Клієнтом
              Профіль Користувача за таких умов:
            </li>
            <ul style="list-style-type: square;">
              <li>порушення через пов’язаний з Клієнтом Профіль Користувача умов цього Договору та Додатків до
                нього; або
              </li>
              <li>отримання скарги на дії реалізовані на Платформі через пов’язаний з Клієнтом Профіль
                Користувача; або
              </li>
              <li>у випадку проблем технічного характеру або загрози виникнення проблем технічного характеру та
                подальшого функціонування Платформи.
              </li>
            </ul>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">IX. Використання механічних засобів
            факсимільного відтворення підпису</h3>

          <ol style="list-style-type: decimal;">
            <li>Цим Сторони домовилися про право використання ТОВ “ВІСТАДІ” Факсиміле для підтвердження
              документів та первинної документації, у тому числі, але не обмежуючись Протоколами-підтвердженнями,
              Актами приймання невиключного права на використання об’єктів авторського права.
            </li>
            <li>2. Відповідно до домовленості Сторін, ТОВ “ВІСТАДІ” має право на використання Факсиміле у якості
              (замість) оригінальних власного підпису уповноваженої особи/уповноважених осіб ТОВ “ВІСТАДІ” та
              відтиску печатки Ліцензіара на документах (де, такий відтиск печатки є обов’язковим згідно вимог
              чинного законодавства України та положень Статуту ТОВ “ВІСТАДІ”), що підписуються з його боку при
              здійсненні правовідносин між Сторонами даного Договору.
            </li>
            <li>Сторони визнають, що зазначені в цьому Договорі документи, завірені Факсиміле ТОВ “ВІСТАДІ”, є
              підписаними належним чином і мають правову силу, прирівнюються до аналогічних документів, оформлених
              оригінальним власноручним підписом уповноваженої особи ТОВ “ВІСТАДІ” та відтиском печатки ТОВ
              “ВІСТАДІ”.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">X. Конфіденційність</h3>

          <ol style="list-style-type: decimal;">

            <li>Сторони зобов’язуються не розголошувати конфіденційну інформацію і не використовувати її, окрім
              як для виконання зобов’язань за цим Договором. Сторона, якій надана конфіденційна інформація,
              зобов’язується вживати заходи до її захисту не менші, ніж заходи прийняті нею для захисту власної
              конфіденційної інформації, вживати заходи для попередження несанкціонованого використання або
              розкриття такої інформації третім особам.
            </li>
            <li>Конфіденційною вважається інформація, отримана в рамках виконання цього Договору, у тому числі,
              але не обмежуючись, технічною, комерційною, персональною та іншої інформацію, що була надана однією
              Стороною іншій, інформація, позначена Стороною яка її передає, позначена грифом «Конфіденційно» або
              «Строго конфіденційно».
            </li>
            <li>Сторона, яка допустила розголошення конфіденційної інформації або не виконала інших вимог щодо
              забезпечення її конфіденційності, несе відповідальність відповідно до законодавства України та має
              відшкодувати іншій Стороні, всі пов’язані з таким розголошенням витрати, за умови, що такі витрати є
              доведеними та підтвердженими відповідними доказами.
            </li>
            <li>4. Сторони не несуть відповідальності за порушення конфіденційності, яке відбулося внаслідок випадку
              або дії обставин непереборної сили, протиправних дій третіх осіб, а так само внаслідок порушення
              конфіденційності за вимогою державних органів згідно з чинним законодавством.
            </li>
            <li>5. Зобов’язання Сторін, викладені в цьому Розділі Договору, діють протягом строку дії цього Договору
              та 3 (трьох) років після його припинення.
            </li>

          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XI. Права та обов’язки Сторін</h3>
          <ol style="list-style-type: decimal;">
            <li>Клієнт має право:</li>
            <ul style="list-style-type: square;">
              <li>використовувати Платформу відповідно до умов та в межах, встановлених цим Договором та Додатками
                до нього;
              </li>
              <li>звернутися у будь-який час за контактними даними ТОВ “ВІСТАДІ”, зазначеними на веб-сайті Платформи
                <a href="https://westudy.ua/online" target="_blank">https://westudy.ua/online</a> для отримання
                консультацій та роз’яснень;
              </li>
              <li>розірвати цей Договір у відповідності до умов цього Договору та Додатків до нього;</li>
              <li>інші права, зазначені в цьому Договорі та Додатках до нього.</li>
            </ul>
            <li>ТОВ “ВІСТАДІ” має право:</li>
            <ul style="list-style-type: square;">
              <li>вільно розпоряджатися Платформою на власний розсуд, передавати право доступу до Платформи третім
                особам, самостійно використовувати Платформу та її частини і компоненти в будь-якій формі і
                будь-яким способом за своїм розсудом, передавати права щодо Платформи, її частин та компонентів
                третім особам без повідомлення Клієнта;
              </li>
              <li>передавати свої зобов’язання та права за цим Договором третім особам без повідомлення Клієнта;
              </li>
              <li>отримувати винагороди, зазначені в цьому Договорі, відповідно до умов цього Договору;</li>
              <li>вносити зміни до моделі співпраці шляхом надання користувачам можливості підключення додаткових
                Розширень відповідно Тарифних планів на власний розсуд у будь-який час;
              </li>
              <li>оновлювати зміст, функціональні можливості Платформи, її частин та компонентів , а також
                користувацький інтерфейс в будь-який час на свій власний розсуд;
              </li>
              <li>у будь-який час змінювати, оновлювати, видаляти та додавати нові частини, компоненти Платформи;
              </li>
              <li>вносити, редагувати або видаляти будь-яку інформацію, розміщену на Платформі на власний розсуд,
                в тому числі змінювати кількість і склад контенту, розміщеного Користувачем;
              </li>
              <li>кваліфікувати особливість роботи Платформи її частин та компонентів як недолік (помилку, дефект
                тощо), а також провести одну з таких дій: скорегувати особливість роботи Платформи її частин та
                компонентів або визнати особливість роботи Платформи її частин та компонентів як таку, що не
                підлягає змінам;
              </li>
              <li>інші права, зазначені в цьому Договорі та Додатках до нього.</li>
            </ul>
            <li>ТОВ “ВІСТАДІ” зобов’язується:</li>
            <ul style="list-style-type: square;">
              <li>надати Ліцензіату Ліцензію на використання Платформи;</li>
              <li>своєчасно та сумлінно виконувати умови цього Договору та Додатків до нього;</li>
              <li>робити все належне для того, щоб робота Платформи відповідала умовам цього Договору та Додатків
                до нього, описам та інструкціям, опублікованим ТОВ “ВІСТАДІ” та щоб Користувачі могли в повній мірі
                користуватися Платформою, її Функціональними Площадками та її технічними можливостями 24 години на
                добу із забезпеченням необхідного рівня безпеки доступу, але ТОВ “ВІСТАДІ” не може гарантувати, що
                Платформа буде завжди доступна в мережі та/або працювати без перешкод/перебоїв через можливість
                проблем технічного характеру, перебоїв та перерв у наданні послуг постачальниками та іншими третіми
                особами, проблем з сервером/серверами, та іншими умовами, які неможливо розумно передбачити;
              </li>
              <li>робити все можливе для усунення та вирішення можливих проблем та перешкод для повноцінного
                функціонування Платформи у розумні строки;
              </li>
              <li>час від часу проводити технологічні перерви, що можуть впливати на доступність можливості
                використання Платформи та/або її окремих частин, Функціональних Площадок;
              </li>
              <li>інші зобов’язання, зазначені в цьому Договорі та Додатках до нього.</li>
            </ul>
            <li>Клієнт, особисто, або в особі уповноваженого представника зобов’язується:</li>
            <ul style="list-style-type: square;">
              <li>використовувати Платформу відповідно до умов цього Договору та Додатків до нього;</li>
              <li>своєчасно та сумлінно виконувати умови цього Договору, Додатків до нього;</li>
              <li>нести повну відповідальність відповідно до умов цього Договору за дії, вчинені через пов’язаний
                з Клієнтом Профіль Користувача та за всі дії фізичної особи, що використовує надісланний через
                пов’язаний з Клієнтом Профіль Користувача спеціальний Код для активації та/або авторизації на
                Платформі;
              </li>
              <li>надавати дійсну, точну і повну інформацію про себе та іншу інформацію, необхідну для виконання
                умов цього Договору;
              </li>
              <li>отримати від кожної особи дані якої будуть вноситися та/або оброблятися через пов’язаний з
                Клієнтом Профіль Користувача в рамках використання Платформи Згоду на обробку персональних даних, що
                дозволяє такі дії та передачу персональних ТОВ “ВІСТАДІ”;
              </li>
              <li>безкоштовно, на правах необмеженої у часі та в засобах обробки персональних даних, передати ТОВ
                “ВІСТАДІ” та пов’язаним з ним особам персональні дані кожної особи дані якої вносилися та/або
                оброблялися через пов’язаний з Клієнтом Профіль Користувача в рамках використання Програмного
                забезпечення;
              </li>
              <li>забезпечувати схоронність свого Логіна і Пароля, необхідних для авторизації Профіля Користувача,
                забезпечувати схоронність отриманих ним Ліцензійних кодів, у тому числі не видаляти, знищувати,
                пошкоджувати такі Ліцензійні коди та дотичні та/бо пов’язані з ними електронні повідомлення,
                інструкції, сповіщення тощо протягом терміну дії цього Договору;
              </li>
              <li>сумлінно виконувати всі інші умови цього Договору та Додатків до нього.</li>
            </ul>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XII. Відповідальність Сторін</h3>
          <ol style="list-style-type: decimal;">
            <li>Сторони несуть відповідальність за невиконання або неналежне виконання зобов’язань, передбачених
              Договором, у відповідності до норм чинного в Україні законодавства.
            </li>
            <li>Платформа надається ТОВ “ВІСТАДІ” «як є» та без будь-яких гарантій. В межах чинного законодавства
              України, ТОВ “ВІСТАДІ” не несе будь-яку відповідальність пов’язану з, включно, але не обмежуючись,
              наданням доступу до Платформи в рамках технічних можливостей.
            </li>
            <li>ТОВ “ВІСТАДІ” не несе відповідальність за невиконання або неналежне виконання зобов’язань, якщо
              неналежне виконання є наслідком порушень умов цього Договору та Додатків до нього з боку Клієнта
              та/або Користувача. Клієнт приймає на себе всі можливі ризики, пов’язані з наданням ним помилкових або
              неточних даних.
            </li>
            <li>ТОВ “ВІСТАДІ” не несе відповідальності за будь-які збитки (прямі, непрямі та випадкові), за інші
              матеріальні та нематеріальні втрати Користувача та/або Клієнта та/або третіх осіб, які виникли в
              результаті порушення роботи Платформи, в результаті її використання або неможливості використання,
              внаслідок втрати важливої для Користувача та/або Клієнта інформації (враховуючи файли) з технічних
              причин, у результаті дій або бездіяльності інших Користувачів.
            </li>
            <li>ТОВ “ВІСТАДІ” не несе відповідальності за зміст Користувацького Контенту та наслідки публікації
              даного контенту.
            </li>
            <li>Користувач несе відповідальність за свої дії в рамках функціонування Платформи у відповідності до
              законодавства України.
            </li>
            <li>Сторони звільняються від відповідальності за затримку у виконанні чи за невиконання своїх
              зобов’язань за цим Договором, якщо така затримка або невиконання спричинені подіями, що перебувають
              поза розумним контролем Сторони, настанню цих подій не можна було запобігти при здійсненні належного
              контролю і ці події не можна було передбачити в момент укладення цього Договору, серед яких: введення
              режиму надзвичайної ситуації, пожежа, вибух, повінь, буря, страйки, стихійні лиха, війни, терористичні
              акти, накладення ембарго, масові безлади або втручання будь-якого державного органу, за умови, що
              Сторона, яка допускає затримку або невиконання, негайно, не пізніше 3 (трьох) календарних днів з
              моменту настання такої ситуації повідомить іншу Сторону про причини затримки або невиконання і докладе
              зусиль, щоб усунути причину такої затримки або невиконання. При цьому будь-які затримки або
              невиконання Договору протягом більш ніж 30 (тридцяти) календарних днів є підставою для розірвання
              Договору протилежною Стороною, або протилежна Сторона (на свій розсуд) може продовжити термін дії
              цього Договору на період часу, рівний тривалості перерви чи затримки.
            </li>
          </ol>
          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XIII. Відшкодування збитків</h3>
          <ol style="list-style-type: decimal;">
            <li>У межах, визначених чинним законодавством України, Клієнт погоджується захищати, відшкодовувати
              та не допускати спричинення для ТОВ “ВІСТАДІ”, його філій, співробітників, директорів, працівників та
              агентів будь-яких та всіх скарг, збитків, ушкоджень, втрат, зобов’язань, вартості чи боргу та витрат
              (включно, але не обмежуючись, винагородою адвоката), що виникають в результаті: (i) використання та
              доступу до Платформи; (ii) невиконання будь-якого пункту з умов цього Договору та Додатків, включно з
              неотриманням будь-якої необхідної згоди або порушення чинного законодавства; чи (iii) порушення
              будь-якого права третьої сторони, в тому числі, прав інтелектуальної власності.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XIV. Вирішення спорів та застосовне
            право</h3>

          <ol style="list-style-type: decimal;">
            <li>Цей Договір та Додатки до нього регулюються чинним законодавством України. Відносини, що не
              врегульовані цим Договором та Додатками до нього напряму регулюються чинним законодавством України.
            </li>
            <li>Усі спори, що виникають між Сторонами щодо Договору та Додатків до нього, або спори, що будь-яким
              чином пов’язані з використанням Платформи та її Функціональних Площадок вирішуються шляхом переговорів
              та консультацій між Сторонами.
            </li>
            <li>3. Якщо спір не може бути вирішено шляхом переговорів та консультацій Сторін протягом 30 днів, спір
              вирішується в судовому порядку відповідно до норм чинного в Україні законодавства.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XV. Зміна Договору та Додатків до
            нього</h3>

          <ol style="list-style-type: decimal;">
            <li>Договір не передбачає можливості внесення змін Клієнтом та/або Користувачем до тексту Договору та
              Додатків до нього.
            </li>
            <li>ТОВ “ВІСТАДІ” залишає за собою право змінювати або доповнювати Договір та будь-який з його
              Додатків в будь-який момент без попереднього повідомлення. Усі зміни, доповнення до Договору та
              Додатків до нього здійснюються та вносяться лише ТОВ “ВІСТАДІ” шляхом затвердження та розміщення нової
              редакції на веб-сторінці Платформи.
            </li>
            <li>Повідомлення про зміни та/або доповнення до Договору розміщується в загальнодоступному місці на
              Платформі, надсилається в приватних повідомленнях до Клієнта та/або на email Клієнта, зазначений при
              реєстрації.
            </li>
            <li>Обов’язок ознайомлення з чинною редакцією Договору та Додатків до нього покладається на Клієнта.
            </li>
            <li>5. Зміни до Договору та Додатків до нього є чинними та обов’язковими для Сторін з моменту їх
              розміщення (оприлюднення) на веб-сторінці Платформи [адреса веб-сторінки]. Продовження використання
              Платформи Користувачем після оприлюднення змін до Договору та Додатків до нього означає прийняття та
              згоду Клієнта з такими змінами та/або доповненнями.
            </li>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XVI. Додатки до Договору</h3>

          <ol style="list-style-type: decimal;">
            <li>Додатки є невід’ємною частиною цього Договору. Додатки включають:</li>
            <ul style="list-style-type: square;">
              <li>Умови та правила використання - Умови та правила використання;</li>
              <li>Політику Конфіденційності - Політика конфіденційності;</li>
              <li>Правила повернення коштів – Правила повернення коштів.</li>
            </ul>
          </ol>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6">XVII. Різне</h3>

          <ol style="list-style-type: decimal;">
            <li>Цей Договір замінює попередні договори, угоди та домовленості між ТОВ “ВІСТАДІ” та Клієнтом
              стосовно предмету цього Договору — права використання Платформи та її Функціональних Площадок, за
              винятком договорів та угод, укладених у письмовій формі, якщо такими договорами не передбачене інакше.
            </li>
            <li>У випадку якщо граничний термін виконання Стороною зобов’язання за Договором припадає на
              неробочий день, таке зобов’язання має бути виконане в найближчий за ним робочий день.
            </li>
            <li>Якщо будь-яка частина цього Договору визнається такою, що не має юридичної сили, всі інші його
              частини залишаються повністю чинними та зберігають юридичну силу.
            </li>
            <li>Клієнт не має права передавати свої права чи обов’язки, що випливають з цього Договору та Додатків
              до нього без письмової згоди ТОВ “ВІСТАДІ”.
            </li>
            <li>Клієнт погоджується отримувати будь-які сповіщення та повідомлення від ТОВ “ВІСТАДІ” в електронному
              форматі, наприклад, через Платформу та email, вказаний при реєстрації.
            </li>
            <li>Не реалізація ТОВ “ВІСТАДІ” будь-якого свого права, передбаченого цим Договором та Додатками до
              нього, не означає відмову від цього або будь-якого іншого права.
            </li>
            <li>Сторони погоджуються, що єдиною редакцією цього Договору, яка має юридичну силу, є редакція,
              розміщена на офіційному веб-сайті Платформі в мережі інтернет, якщо інше не погоджено умовами
              укладеного в письмовій формі між Клієнтом та ТОВ “ВІСТАДІ” ліцензійного договору на дозвіл
              використання Платформи та/або її окремої Функціональної Площадки.
            </li>
            <li>Сторони, за взаємною згодою, можуть змінювати свої правовідносини за Договором шляхом укладання
              додаткових угод до чинного Договору.
            </li>
          </ol>

        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "HomeAbout"
}
</script>

<style scoped>

</style>